import React from 'react';
import { ListItem, ListItemText, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore, ChevronRight, Folder, FolderOpen } from '@mui/icons-material';
import './MenuItemPastas.css';

interface MenuItemPastasProps {
  name: string;
  id: number;
  type: 'pasta' | 'pagina';
  onClick: () => void;
  onContextMenu?: (event: React.MouseEvent) => void;
  children?: React.ReactNode;
  isOpen?: boolean;
  icon?: React.ReactNode;
  level?: number;
  style?: React.CSSProperties; 
}

const MenuItemPastas: React.FC<MenuItemPastasProps> = ({ name, id, type, onClick, onContextMenu, children, isOpen, icon, level = 0, style }) => {
  const handleClick = () => {
    onClick();
  };
 
  const renderIcon = () => {
    if (type === 'pasta') {
      return isOpen ? <FolderOpen /> : <Folder />;
    }
    return icon;
  };

  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        onContextMenu={onContextMenu}
        className={`menu-item-pastas-${type}`}
        sx={{ pl: 2 + level * 2 }}
        style={style} 
      >
        {renderIcon()}
        <ListItemText primary={name} sx={{ marginLeft: 1 }} />
        {type === 'pasta' && (isOpen ? <ExpandMore /> : <ChevronRight />)}
      </ListItem>
      {type === 'pasta' && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
};

export default MenuItemPastas;
