import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
console.log('API URL:', apiUrl);

const api = axios.create({
  baseURL: apiUrl, // Substitua pelo URL do seu backend se for diferente
});



// Interceptor para adicionar o token de autenticação a todas as requisições
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Interceptor para lidar com respostas de erro
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      // Redirecionar para a página de login ou tomar outra ação apropriada
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

// Funções para Pastas de Usuário
export const fetchPastasUsuario = () => api.get('/pastasUsuario');
export const createPastaUsuario = (nome: string, parentId?: number, usuarioId?: number) => {
  return api.post('/pastasUsuario', { nome, pasta_pai_id: parentId, usuario_id: usuarioId });
};
export const deletePastaUsuario = (id: number) => api.delete(`/pastasUsuario/${id}`);
export const updatePastaUsuario = (id: number, nome: string) => api.put(`/pastasUsuario/${id}`, { nome });

// Funções para Páginas de Usuário
export const fetchPaginasUsuario = () => api.get('/paginasUsuario');
export const createPaginaUsuario = (titulo: string, pastaId: number, usuarioId: number) => {
  return api.post('/paginasUsuario', { titulo, tipoPagina: 'generica', pasta_id: pastaId, usuario_id: usuarioId });
};
export const deletePaginaUsuario = (id: number) => api.delete(`/paginasUsuario/${id}`);
export const updatePaginaUsuario = (id: number, titulo: string) => api.put(`/paginasUsuario/${id}`, { titulo });
export const updateComponenteOrder = async (pageId: number, componentes: { id: number; ordem: number }[]) => {
  return api.put(`/paginasUsuario/${pageId}/updateOrder`, { pageId, componentes });
};

// Funções para Componentes
export const fetchComponentes = (paginaId: number) => api.get(`/componentes/${paginaId}`);
export const createComponente = (type: string, dados: any, pagina_id: number) => api.post('/componentes', { type, dados, pagina_id });
export const deleteComponente = (id: number) => api.delete(`/componentes/${id}`);
export const updateComponente = (id: number, dados: any) => api.put(`/componentes/${id}`, { dados });



// Funções para atualizar ordem dos componentes
export const updateComponentOrder = (componentes: { id: number; ordem: number }[]) => api.put('/componentes/updateOrder', { componentes });


// Funções para autenticação
export const login = (email: string, senha: string) => api.post('/auth/login', { email, senha });
export const register = (nome: string, email: string, senha: string) => api.post('/auth/register', { nome, email, senha });
export const socialLogin = (provider: string, token: string) => api.post(`/auth/${provider}/callback`, { token });

// Funções para Revisão
export const fetchRevisoes = (usuarioId: number, tipo: 'espacada' | 'periodica') => {
  return api.get(`/revisoes/${usuarioId}?tipo=${tipo}`);
};

export const createRevisaoEspacada = (usuarioId: number, componenteId: number) => api.post('/revisoes/espacada', { usuarioId, componenteId });
export const createRevisaoPeriodica = (usuarioId: number, componenteId: number) => api.post('/revisoes/periodica', { usuarioId, componenteId });
export const registrarSessaoRevisaoEspacada = (usuarioId: number, itemId: number, resposta: string, sucesso: boolean) => api.post('/revisoes/espacada/sessao', { usuarioId, itemId, resposta, sucesso });
export const registrarSessaoRevisaoPeriodica = (usuarioId: number, itemId: number) => api.post('/revisoes/periodica/sessao', { usuarioId, itemId });

export const fetchComponentesParaRevisao = (baralhoId: number) => {
  return api.get(`/revisoes/componentes/${baralhoId}`);
};

export const registrarRespostaRevisao = (componenteId: number, resposta: string, tipo: 'espacada' | 'periodica') => {
  return api.post(`/revisoes/responder`, { componenteId, resposta, tipo });
};

export const editarComponente = (componenteId: number, acao: string) => {
  return api.put(`/componentes/${componenteId}/editar`, { acao });
};

// Funções para atualizar e-mail e senha do usuário
export const updateEmail = (id: number, email: string) => api.put(`/usuarios/${id}/email`, { email });
export const updateSenha = (id: number, senha: string) => api.put(`/usuarios/${id}/senha`, { senha });

// Funções para Baralhos
export const fetchBaralhos = () => api.get(`/baralhos`);
export const createBaralho = (usuarioId: number, nome: string, baralhoPaiId?: number) => {
  return api.post('/baralhos', { usuario_id: usuarioId, nome, baralho_pai_id: baralhoPaiId });
};
export const updateBaralho = (baralhoId: number, nome: string) => api.put(`/baralhos/${baralhoId}`, { nome });
export const deleteBaralho = (baralhoId: number) => api.delete(`/baralhos/${baralhoId}`);
export const getBaralhoDeleteInfo = (baralhoId: number) => api.get(`/baralhos/${baralhoId}/delete-info`);

// Funções para Componentes e Itens de Revisão
export const createComponenteRevisao = async (usuarioId: number, type: string, dados: any, baralhoId: number) => {
  try {
    return await api.post('/componentes/revisao', { usuarioId, type, dados, baralho_id: baralhoId });
  } catch (error) {
    console.error('Error creating revision component:', error);
    throw error;
  }
};

export const createItemRevisaoEspacada = async (usuarioId: number, componenteId: number, baralhoId: number) => {
  try {
    return await api.post('/revisoes/espacada', { usuarioId, componenteId, baralhoId });
  } catch (error) {
    console.error('Error creating spaced repetition item:', error);
    throw error;
  }
};

export const fetchItensRevisaoEspacada = () => api.get('/itensRevisaoEspacada');

// Função para buscar itens de revisão espaçada por baralho
export const fetchItensRevisaoEspacadaByBaralho = (baralhoId: number) => {
  return api.get(`/itensRevisaoEspacada/${baralhoId}`);
};

// Função para criar componentes e associá-los aos baralhos
export const createComponenteBaralho = (baralhoId: number, type: string, dados: any) => {
  return api.post('/componentesBaralho', { baralho_id: baralhoId, type, dados });
};


// Função para buscar todos os componentes de um baralho específico
export const fetchComponentesByBaralho = (baralhoId: number) => {
  return api.get(`/baralhos/${baralhoId}/componentes`);
};

// src/services/api.ts

// Funções para Estatísticas
export const fetchEstatisticas = (usuarioId: number, dias: number) => {
  return api.get(`/estatisticas/${usuarioId}?dias=${dias}`);
};


export const fetchItensPorBaralho = (usuarioId: number) => {
  return api.get(`/estatisticas/baralhos/${usuarioId}`);
};

// Função para buscar todos os componentes de uma página específica
export const fetchComponentesPorPagina = (paginaId: number) => {
  return api.get(`/componentes/pagina/${paginaId}`);
};

export const addComponentesPaginaRevisao = async (usuarioId:number, componenteId:number, baralhoId: number) => {
  try {
    return await api.post('/componentes/revisao/pagina', {usuarioId, componenteId, baralho_id: baralhoId });
  } catch (error) {
    console.error('Error creating revision component:', error);
    throw error;
  }
};


export default api;
