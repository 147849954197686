import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableBody, TableCell, TableRow, IconButton, Checkbox, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import MenuSelecaoComponentes from './MenuSelecaoComponentes';
import { createComponenteRevisao, createItemRevisaoEspacada, addComponentesPaginaRevisao } from '../services/api';

import CompleteTexto from '../components/organisms/CompleteTexto/CompleteTexto';
import ImageOcclusion from '../components/organisms/ImageOcclusion/ImageOcclusion';
import ImageOcclusionPorClique from '../components/organisms/ImageOcclusionPorClique/ImageOcclusionPorClique';
import PreencherQuadro from '../components/organisms/PreencherQuadro/PreencherQuadro';
import SequencingGame from '../components/organisms/SequencingGame/SequencingGame';
import PreencherItemCategoria from '../components/organisms/PreencherItemCategoria/PreencherItemCategoria';
import ArrastarSoltarCategoria from '../components/organisms/ArrastarSoltarCategoria/ArrastarSoltarCategoria';
import Ordenacao from '../components/organisms/Ordenacao/Ordenacao';
import QuizDigitado from '../components/organisms/QuizDigitado/QuizDigitado';
import QuizMultiplaEscolha from '../components/organisms/QuizMultiplaEscolha/QuizMultiplaEscolha';
import TimeLine from '../components/organisms/TimeLine/TimeLine';
import FlipCard from '../components/organisms/FlipCard/FlipCard';
import RevealCard from '../components/organisms/RevealCard/RevealCard';
import TreinoFlashCard from '../components/organisms/treino/TreinoFlashCard/TreinoFlashCard';
import PerguntaDigitado from '../components/organisms/PerguntaDigitado/PerguntaDigitado';
import PerguntaMultiplaEscolha from '../components/organisms/PerguntaMultiplaEscolha/PerguntaMultiplaEscolha';
import FlashCard from '../components/organisms/FlashCard/FlashCard';
import DecisionTreeComic from '../components/organisms/DecisionTreeComic/DecisionTreeComic';
import Texto from '../components/organisms/Texto/Texto';
import ExcalidrawViewer from '../components/organisms/ExcalidrawViewer/ExcalidrawViewer';
import './AddComponentesDePaginas.css';

interface AddComponentesDePaginasProps {
  open: boolean;
  onClose: () => void;
  baralhoId: number;
  usuarioId: number;
  onComponentesAdded: () => void;  
}

const AddComponentesDePaginas: React.FC<AddComponentesDePaginasProps> = ({ open, onClose, baralhoId, usuarioId , onComponentesAdded,}) => {
  const [selectedComponentes, setSelectedComponentes] = useState<any[]>([]);
  const [componentesDaPagina, setComponentesDaPagina] = useState<any[]>([]);
  const [selectedComponente, setSelectedComponente] = useState<any | null>(null);
  const [jsonDialogOpen, setJsonDialogOpen] = useState<boolean>(false);
  const [checked, setChecked] = useState<number[]>([]);
  const [viewDialogOpen, setViewDialogOpen] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [selectedPageId, setSelectedPageId] = useState<number | null>(null); // Estado para armazenar a página selecionada


  // Quando o modal abrir, limpar os estados
  useEffect(() => {
    if (open) {
      setSelectedComponentes([]);
      setComponentesDaPagina([]);
      setChecked([]);
      setSelectedComponente(null);
    }
  }, [open]);

  const handleSelectComponent = (componentes: any[], pageId: number | null) => {
    setComponentesDaPagina(componentes);
    setChecked([]);
    setSelectedPageId(pageId);
  };

  const handleToggle = (componenteId: number) => {
    const currentIndex = checked.indexOf(componenteId);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(componenteId);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleToggleAll = () => {
    if (checked.length === componentesDaPagina.length) {
      setChecked([]);
    } else {
      const allIds = componentesDaPagina.map((componente) => componente.id);
      setChecked(allIds);
    }
  };

  const handleViewComponent = (componente: any) => {
    setSelectedComponente(componente);
    setViewDialogOpen(true);
  };

  const handleViewJson = (componente: any) => {
    setSelectedComponente(componente);
    setJsonDialogOpen(true);
  };
  

  const handleSave = async () => {
    try {
      for (const componenteId of checked) {
        const componente = componentesDaPagina.find((comp) => comp.id === componenteId);
        if (componente) {
          await addComponentesPaginaRevisao(usuarioId, componente.id, baralhoId);
        }
      }
      onComponentesAdded();
      onClose();
    } catch (error) {
      console.error('Erro ao salvar componentes:', error);
    }
  };

  const renderComponentePreview = () => {
    if (!selectedComponente) return null;

    switch (selectedComponente.type) {
      case 'CompleteTexto':
        return <CompleteTexto {...selectedComponente.dados} />;
      case 'ImageOcclusion':
        return <ImageOcclusion data={selectedComponente.dados} />;
      case 'ImageOcclusionPorClique':
        return <ImageOcclusionPorClique data={selectedComponente.dados} />;
      case 'PreencherQuadro':
        return <PreencherQuadro data={selectedComponente.dados} />;
      case 'SequencingGame':
        return <SequencingGame data={selectedComponente.dados} />;
      case 'PreencherItemCategoria':
        return <PreencherItemCategoria data={selectedComponente.dados} />;
      case 'ArrastarSoltarCategoria':
        return <ArrastarSoltarCategoria data={selectedComponente.dados} />;
      case 'Ordenacao':
        return <Ordenacao data={selectedComponente.dados} />;
      case 'QuizDigitado':
        return <QuizDigitado data={selectedComponente.dados} />;
      case 'QuizMultiplaEscolha':
        return <QuizMultiplaEscolha data={selectedComponente.dados} />;
      case 'TimeLine':
        return <TimeLine data={selectedComponente.dados} />;
      case 'FlipCard':
        return <FlipCard {...selectedComponente.dados} />;
      case 'RevealCard':
        return <RevealCard {...selectedComponente.dados} />;
      case 'TreinoFlashCard':
        return <TreinoFlashCard cards={selectedComponente.dados.treinoFlashCardsData || []} onComplete={() => {}} />;
      case 'PerguntaDigitado':
        return <PerguntaDigitado {...selectedComponente.dados} />;
      case 'PerguntaMultiplaEscolha':
        return <PerguntaMultiplaEscolha {...selectedComponente.dados} />;
      case 'FlashCard':
        return <FlashCard {...selectedComponente.dados} />;
      case 'DecisionTreeComic':
        return <DecisionTreeComic data={selectedComponente.dados} />;
      case 'Texto':
        return <Texto html={selectedComponente.dados.html} />;
      case 'Excalidraw':
        return <ExcalidrawViewer {...selectedComponente.dados} />;
      default:
        return null;
    }
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={isFullScreen ? false : 'md'}
      PaperProps={{
        style: isFullScreen
          ? { width: '100%', height: '100%', margin: 0, maxWidth: 'none', maxHeight: 'none' }
          : {},
      }}
    >
      <DialogTitle>Adicionar Componentes das Páginas</DialogTitle>
      <DialogContent className='add-componentes-de-paginas-Conteudo'>
        <MenuSelecaoComponentes
          onSelectComponent={handleSelectComponent}
          isVisible={true}
          toggleVisibility={() => {}}
        />
        {selectedPageId ? (
          componentesDaPagina.length > 0 ? (
            <Table>
              <TableBody>
                {componentesDaPagina.length > 1 && (
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={checked.length === componentesDaPagina.length}
                        onChange={handleToggleAll}
                        indeterminate={checked.length > 0 && checked.length < componentesDaPagina.length}
                      />
                      Selecionar Todos
                    </TableCell>
                  </TableRow>
                )}
                {componentesDaPagina.map((componente) => (
                  <TableRow key={componente.id}>
                    <TableCell>
                      <Checkbox
                        checked={checked.indexOf(componente.id) !== -1}
                        onChange={() => handleToggle(componente.id)}
                      />
                    </TableCell>
                    <TableCell>
                      <strong>Tipo:</strong> {componente.type}
                      <br />
                      <span
                        onClick={() => handleViewJson(componente)}
                        style={{ cursor: 'pointer', color: 'blue' }}
                      >
                        <strong>Dados:</strong> {JSON.stringify(componente.dados).substring(0, 50)}...
                      </span>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleViewComponent(componente)}>
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography variant="body1" color="textSecondary" align="center">
              Não há itens disponíveis na página selecionada.
            </Typography>
          )
        ) : (
          <Typography variant="body1" color="textSecondary" align="center">
            Selecione uma página no Menu para visualizar os itens.
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <IconButton onClick={toggleFullScreen}>
          {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </IconButton>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSave} color="primary" disabled={checked.length === 0}>
          Adicionar
        </Button>
      </DialogActions>

      {/* Dialog for viewing the full JSON */}
      <Dialog open={jsonDialogOpen} onClose={() => setJsonDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Dados Completos</DialogTitle>
        <DialogContent>
          <pre>{JSON.stringify(selectedComponente?.dados, null, 2)}</pre>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setJsonDialogOpen(false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for viewing the component preview */}
      <Dialog open={viewDialogOpen} onClose={() => setViewDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Visualizar Componente</DialogTitle>
        <DialogContent>{renderComponentePreview()}</DialogContent>
        <DialogActions>
          <Button onClick={() => setViewDialogOpen(false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default AddComponentesDePaginas;
