import React, { useEffect, useState } from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { TreeNode } from 'primereact/treenode';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';

import {
  fetchBaralhos,
  fetchComponentesByBaralho,
  deleteComponente,
  updateComponente,
} from '../services/api';
import AddComponenteModal from './AddComponenteModal';

import CompleteTexto from '../components/organisms/CompleteTexto/CompleteTexto';
import ImageOcclusion from '../components/organisms/ImageOcclusion/ImageOcclusion';
import ImageOcclusionPorClique from '../components/organisms/ImageOcclusionPorClique/ImageOcclusionPorClique';
import ImageOcclusionEditor from '../components/edicao/ImageOcclusionEditor/ImageOcclusionEditor';
import PreencherQuadro from '../components/organisms/PreencherQuadro/PreencherQuadro';
import PreencherQuadroEditor from '../components/edicao/PreencherQuadroEditor/PreencherQuadroEditor';
import SequencingGame from '../components/organisms/SequencingGame/SequencingGame';
import SequencingGameEditor from '../components/edicao/SequencingGameEditor/SequencingGameEditor';
import PreencherItemCategoria from '../components/organisms/PreencherItemCategoria/PreencherItemCategoria';
import PreencherItemCategoriaEditor from '../components/edicao/PreencherItemCategoriaEditor/PreencherItemCategoriaEditor';
import ArrastarSoltarCategoria from '../components/organisms/ArrastarSoltarCategoria/ArrastarSoltarCategoria';
import ArrastarSoltarCategoriaEditor from '../components/edicao/ArrastarSoltarCategoriaEditor/ArrastarSoltarCategoriaEditor';
import Ordenacao from '../components/organisms/Ordenacao/Ordenacao';
import OrdenacaoEditor from '../components/edicao/OrdenacaoEditor/OrdenacaoEditor';
import QuizDigitado from '../components/organisms/QuizDigitado/QuizDigitado';
import QuizMultiplaEscolha from '../components/organisms/QuizMultiplaEscolha/QuizMultiplaEscolha';
import TimeLine from '../components/organisms/TimeLine/TimeLine';
import TimeLineEditor from '../components/edicao/TimeLineEditor/TimeLineEditor';
import FlipCard from '../components/organisms/FlipCard/FlipCard';
import RevealCard from '../components/organisms/RevealCard/RevealCard';
import CardEditor from '../components/edicao/CardEditor/CardEditor';
import TreinoFlashCard from '../components/organisms/treino/TreinoFlashCard/TreinoFlashCard';
import TreinoFlashCardsEditor from '../components/edicao/TreinoFlashCardsEditor/TreinoFlashCardsEditor';
import TermoDefinicaoEditor from '../components/edicao/TermoDefinicaoEditor/TermoDefinicaoEditor';
import PerguntaDigitado from '../components/organisms/PerguntaDigitado/PerguntaDigitado';
import PerguntaDigitadoEditor from '../components/edicao/PerguntaDigitadoEditor/PerguntaDigitadoEditor';
import PerguntaMultiplaEscolha from '../components/organisms/PerguntaMultiplaEscolha/PerguntaMultiplaEscolha';
import PerguntaMultiplaEscolhaEditor from '../components/edicao/PerguntaMultiplaEscolhaEditor/PerguntaMultiplaEscolhaEditor';
import FlashCard from '../components/organisms/FlashCard/FlashCard';
import QuizMultiploImediato from '../components/organisms/QuizMultiploImediato/QuizMultiploImediato';
import DecisionTreeComic from '../components/organisms/DecisionTreeComic/DecisionTreeComic';
import Texto from '../components/organisms/Texto/Texto';
import TextoEditor from '../components/edicao/TextoEditor/TextoEditor';
import ExcalidrawViewer from '../components/organisms/ExcalidrawViewer/ExcalidrawViewer';
import ExcalidrawEditor from '../components/edicao/ExcalidrawEditor/ExcalidrawEditor';
import TreinoTermoDefinicao from '../components/organisms/treino/TreinoTermoDefinicao/TreinoTermoDefinicao';
import TreinoTermoDefinicaoEditor from '../components/edicao/TreinoTermoDefinicaoEditor/TreinoTermoDefinicaoEditor';
import ItensSelecao from '../components/organisms/ItensSelecao/ItensSelecao';
import ItensSelecaoEditor from '../components/edicao/ItensSelecaoEditor/ItensSelecaoEditor';
import { Phase, Activity, Occlusion, TreinoFlashCardData } from '../types';
import Toast from './Toast';

import './RevisaoEspacadaEdicaoPage.css';

interface RevisaoEspacadaEdicaoPageProps {
  usuarioId: number;
}

interface Baralho {
  id: number;
  nome: string;
  subBaralhos: Baralho[];
}

interface Componente {
  id: number;
  tipo: string;
  dados: any;
}

const RevisaoEspacadaEdicaoPage: React.FC<RevisaoEspacadaEdicaoPageProps> = ({ usuarioId }) => {
  const [baralhos, setBaralhos] = useState<TreeNode[]>([]);
  const [selectedBaralho, setSelectedBaralho] = useState<Baralho | null>(null);
  const [componentes, setComponentes] = useState<Componente[]>([]);
  const [isAddComponentModalOpen, setIsAddComponentModalOpen] = useState(false);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [editingComponente, setEditingComponente] = useState<Componente | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [componenteToDelete, setComponenteToDelete] = useState<Componente | null>(null);
  const [viewingComponente, setViewingComponente] = useState<Componente | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [tipoFiltro, setTipoFiltro] = useState<string[]>([]);
  const [dadosFiltro, setDadosFiltro] = useState<string>('');
  const [isDadosDialogOpen, setIsDadosDialogOpen] = useState<boolean>(false);
  const [dadosCompletos, setDadosCompletos] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchBaralhos();
      const baralhosData = await transformData(response.data);
      setBaralhos(baralhosData);
    };
    fetchData();
  }, []);

  const transformData = async (data: Baralho[]): Promise<TreeNode[]> => {
    return Promise.all(
      data.map(async (item: Baralho) => {
        return {
          key: item.id.toString(),
          data: {
            id: item.id,
            nome: item.nome,
          },
          children: item.subBaralhos ? await transformData(item.subBaralhos) : [],
        };
      })
    );
  };

  const handleBaralhoClick = async (node: TreeNode) => {
    const response = await fetchComponentesByBaralho(node.data.id);
    setComponentes(response.data);
    setSelectedBaralho(node.data);
  };

  const handleEditComponent = (componente: Componente) => {
    setEditingComponente(componente);
    setIsEditorOpen(true);
  };

  const handleDeleteComponent = (componente: Componente) => {
    setComponenteToDelete(componente);
    setDeleteDialogOpen(true);
  };

  const handleViewComponent = (componente: Componente) => {
    setViewingComponente(componente);
  };

  const confirmDelete = async () => {
    if (componenteToDelete) {
      try {
        await deleteComponente(componenteToDelete.id);
        setComponentes((prevState) => prevState.filter((comp) => comp.id !== componenteToDelete.id));
      } catch (error) {
        console.error('Erro ao deletar componente:', error);
      } finally {
        setDeleteDialogOpen(false);
        setComponenteToDelete(null);
      }
    }
  };

  const renderFiltros = () => {
    const tiposDisponiveis = Array.from(new Set(componentes.map((comp) => comp.tipo)));

    return (
      <div className="RevisaoEspacadaEdicaoPage-filtros">
        <MultiSelect
          value={tipoFiltro}
          options={tiposDisponiveis.map((tipo) => ({ label: tipo, value: tipo }))}
          onChange={(e) => setTipoFiltro(e.value)}
          placeholder="Filtrar por Tipo"
          display="chip"
          style={{ minWidth: '12rem', marginRight: '1rem' }}
        />
        <InputText
          value={dadosFiltro}
          onChange={(e) => setDadosFiltro(e.target.value)}
          placeholder="Buscar nos Dados"
          style={{ minWidth: '12rem' }}
        />
      </div>
    );
  };

  const handleDadosClick = (dados: string) => {
    const dadosFormatados = JSON.stringify(JSON.parse(dados), null, 2);
    setDadosCompletos(dadosFormatados);
    setIsDadosDialogOpen(true);
  };

  const renderComponentes = () => {
    const componentesFiltrados = componentes.filter((componente) => {
      const tipoMatch = tipoFiltro.length === 0 || tipoFiltro.includes(componente.tipo);
      const dadosMatch = componente.dados && JSON.stringify(componente.dados).toLowerCase().includes(dadosFiltro.toLowerCase());
      return tipoMatch && dadosMatch;
    });

    return componentesFiltrados.map((componente) => {
      const dadosString = JSON.stringify(componente.dados);
      const dadosResumidos = dadosString.length > 100 ? `${dadosString.slice(0, 100)}...` : dadosString;

      return (
        <tr key={componente.id} className="RevisaoEspacadaEdicaoPage-ListaComponentes">
          <td>
            <span><strong>Tipo:</strong> {componente.tipo}</span>
            <br />
            <span
              className="RevisaoEspacadaEdicaoPage-DadosResumo"
              onClick={() => handleDadosClick(dadosString)}
              style={{ cursor: 'pointer', color: 'blue' }}
            >
              <strong>Dados:</strong> {dadosResumidos}
            </span>
          </td>
          <td>
            <IconButton onClick={() => handleViewComponent(componente)}>
              <VisibilityIcon />
            </IconButton>
            <IconButton onClick={() => handleEditComponent(componente)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDeleteComponent(componente)}>
              <DeleteIcon />
            </IconButton>
          </td>
        </tr>
      );
    });
  };

  const renderViewer = () => {
    if (!viewingComponente) return null;

    switch (viewingComponente.tipo) {
      case 'CompleteTexto':
        return <CompleteTexto id={viewingComponente.id} title={viewingComponente.dados.title || ''} texto={viewingComponente.dados.texto || ''} />;
      case 'ImageOcclusion':
        return viewingComponente.dados.mode === 'digitada' ? (
          <ImageOcclusion data={viewingComponente.dados} />
        ) : (
          <ImageOcclusionPorClique data={viewingComponente.dados} />
        );
      case 'PreencherQuadro':
        return <PreencherQuadro data={viewingComponente.dados} />;
      case 'SequencingGame':
        return <SequencingGame data={viewingComponente.dados} />;
      case 'PreencherItemCategoria':
        return <PreencherItemCategoria data={viewingComponente.dados} />;
      case 'ItensSelecao':
        return <ItensSelecao data={viewingComponente.dados} />;
      case 'ArrastarSoltarCategoria':
        return <ArrastarSoltarCategoria data={viewingComponente.dados} />;
      case 'Ordenacao':
        return <Ordenacao data={viewingComponente.dados} />;
      case 'QuizDigitado':
        return <QuizDigitado data={viewingComponente.dados} />;
      case 'QuizMultiplaEscolha':
        return <QuizMultiplaEscolha data={viewingComponente.dados} />;
      case 'TimeLine':
        return <TimeLine data={viewingComponente.dados} />;
      case 'Card':
        return viewingComponente.dados.mode === 'flip' ? (
          <FlipCard id={viewingComponente.id} front={viewingComponente.dados.front} back={viewingComponente.dados.back} />
        ) : (
          <RevealCard id={viewingComponente.id} front={viewingComponente.dados.front} back={viewingComponente.dados.back} comment={viewingComponente.dados.comment} />
        );
      case 'TreinoFlashCard':
        return <TreinoFlashCard cards={viewingComponente.dados.treinoFlashCardsData || []} onComplete={() => {}} />;
      case 'TreinoTermoDefinicao':
        return <TreinoTermoDefinicao dados={viewingComponente.dados} />;
      case 'PerguntaDigitado':
        return <PerguntaDigitado id={viewingComponente.id} pergunta={viewingComponente.dados.question} respostaCorreta={viewingComponente.dados.respostaCorreta} comment={viewingComponente.dados.comment} />;
      case 'FlashCard':
        return <FlashCard title={viewingComponente.dados.title} flashcardData={viewingComponente.dados.flashcardData} />;
      case 'QuizMultiploImediato':
        return <QuizMultiploImediato data={viewingComponente.dados.questionData} />;
      case 'DecisionTreeComic':
        return <DecisionTreeComic data={viewingComponente.dados} />;
      case 'Texto':
        return <Texto html={viewingComponente.dados.html} />;
      case 'Excalidraw':
        return <ExcalidrawViewer elements={viewingComponente.dados.elements} appState={viewingComponente.dados.appState} />;
      case 'PerguntaMultiplaEscolha':
        return <PerguntaMultiplaEscolha
            question={viewingComponente.dados.question || ''}
            options={viewingComponente.dados.options || []}
            questionType={viewingComponente.dados.questionType || 'single'}
            comment={viewingComponente.dados.comment || ''}
          />;
      default:
        return null;
    }
  };

  const renderEditor = () => {
    if (!editingComponente) return null;

    return (
      <div>
        {(() => {
          switch (editingComponente.tipo) {
            case 'CompleteTexto':
              return (
                <TextoEditor
                  initialContent={editingComponente.dados.texto || ''}
                  onSave={handleSaveComponentCompleteTexto}
                  onCancel={() => setIsEditorOpen(false)}
                />
              );
            case 'ImageOcclusion':
              return (
                <ImageOcclusionEditor
                  initialData={editingComponente.dados}
                  onSave={handleSaveComponentImageOcclusion}
                  onCancel={() => setIsEditorOpen(false)}
                />
              );
            case 'PreencherQuadro':
              return (
                <PreencherQuadroEditor
                  initialData={editingComponente.dados}
                  onSave={handleSaveComponentPreencherQuadro}
                  onCancel={() => setIsEditorOpen(false)}
                />
              );
            case 'SequencingGame':
              return (
                <SequencingGameEditor
                  title={editingComponente.dados.title}
                  phases={editingComponente.dados.phases || []}
                  activities={editingComponente.dados.activities || []}
                  comment={editingComponente.dados.comment || ''}
                  considerarOrdem={editingComponente.dados.considerarOrdem ?? false}
                  onSave={handleSaveComponentSequencingGame}
                  onCancel={() => setIsEditorOpen(false)}
                />
              );
            case 'PreencherItemCategoria':
              return (
                <PreencherItemCategoriaEditor
                  title={editingComponente.dados.title || ''}
                  categories={editingComponente.dados.categories || {}}
                  comment={editingComponente.dados.comment || ''}
                  considerarOrdem={editingComponente.dados.considerarOrdem ?? false}
                  onSave={handleSaveComponentPreencherItemCategoria}
                  onCancel={() => setIsEditorOpen(false)}
                />
              );
            case 'PreencherItensSelecao':
              return (
                <ItensSelecaoEditor
                  question={editingComponente.dados.question || ''}
                  correctItems={editingComponente.dados.correctItems || []}
                  incorrectItems={editingComponente.dados.incorrectItems || []}
                  comment={editingComponente.dados.comment || ''}
                  onSave={handleSaveComponentItensSelecao}
                  onCancel={() => setIsEditorOpen(false)}
                />
              );
              case 'ArrastarSoltarCategoria':
                return (
                  <ArrastarSoltarCategoriaEditor
                    title={editingComponente.dados.title || ''}
                    question={editingComponente.dados.question || ''}
                    categories={Array.isArray(editingComponente.dados.categories) ? editingComponente.dados.categories : {}}
                    uncategorizedItems={Array.isArray(editingComponente.dados.uncategorizedItems) ? editingComponente.dados.uncategorizedItems : []}
                    comment={editingComponente.dados.comment || ''}                    
                    onSave={handleSaveComponentArrastarSoltarCategoria}
                    onCancel={() => setIsEditorOpen(false)}
                  />
                );              
            case 'Ordenacao':
              return (
                <OrdenacaoEditor
                  initialStages={Array.isArray(editingComponente.dados.stages) ? editingComponente.dados.stages : []}
                  question={editingComponente.dados.question || ''}
                  comment={editingComponente.dados.comment || ''}
                  onSave={handleSaveComponentOrdenacao}
                  onCancel={() => setIsEditorOpen(false)}
                />
              );
            case 'PerguntaDigitado':
              return (
                <PerguntaDigitadoEditor
                  initialQuestion={editingComponente.dados.question || ''}
                  initialAnswer={editingComponente.dados.respostaCorreta || ''}
                  initialComment={editingComponente.dados.comment || ''}
                  onSave={handleSaveComponentPerguntaDigitado}
                  onCancel={() => setIsEditorOpen(false)}
                  mode={editingComponente.id === 0 ? 'add' : 'edit'}
                />
              );
            case 'PerguntaMultiplaEscolha':
              return (
                <PerguntaMultiplaEscolhaEditor
                  initialQuestion={editingComponente.dados.question || ''}
                  initialOptions={editingComponente.dados.options || [{ text: '', correct: false }]}
                  initialQuestionType={editingComponente.dados.questionType || 'single'}
                  initialComment={editingComponente.dados.comment || ''}
                  onSave={handleSaveComponentPerguntaMultiplaEscolha}
                  onCancel={() => setIsEditorOpen(false)}
                  mode={editingComponente.id === 0 ? 'add' : 'edit'}
                />
              );
            case 'Card':
              return (
                <CardEditor
                  initialFront={editingComponente.dados.front || ''}
                  initialBack={editingComponente.dados.back || ''}
                  initialComment={editingComponente.dados.comment || ''}
                  initialMode={editingComponente.dados.mode || ''}
                  onSave={handleSaveComponentCard}
                  onCancel={() => setIsEditorOpen(false)}
                />
              );
            case 'TreinoFlashCard':
              return (
                <TreinoFlashCardsEditor
                  initialCards={editingComponente.dados.treinoFlashCardsData || []}
                  onSave={handleSaveComponentTreinoFlashCard}
                  onCancel={() => setIsEditorOpen(false)}
                />
              );
            case 'TreinoTermoDefinicao':
              return (
                <TreinoTermoDefinicaoEditor
                  onSave={handleSaveComponentTreinoTermoDefinicao}
                  onCancel={() => setIsEditorOpen(false)}
                />
              );
            case 'TimeLine':
              return (
                <TimeLineEditor
                  title={editingComponente.dados.title}
                  phases={editingComponente.dados.phases || []}
                  considerarOrdem={editingComponente.dados.considerarOrdem ?? false}
                  onSave={handleSaveComponentTimeLine}
                  onCancel={() => setIsEditorOpen(false)}
                />
              );
            case 'Texto':
              return (
                <TextoEditor
                  initialContent={editingComponente.dados.html || ''}
                  onSave={handleSaveComponentTexto}
                  onCancel={() => setIsEditorOpen(false)}
                />
              );
            case 'Excalidraw':
              return (
                <ExcalidrawEditor
                  initialData={{
                    elements: editingComponente.dados.elements ?? [],
                    appState: editingComponente.dados.appState ?? {},
                  }}
                  onSave={(data) => handleSaveComponentExcalidraw(data)}
                  onCancel={() => setIsEditorOpen(false)}
                />
              );
            default:
              return null;
          }
        })()}
        {successMessage && (
          <div className="success-message">
            {successMessage}
          </div>
        )}
      </div>
    );
  };

  const handleSaveComponentCompleteTexto = async (texto: string) => {
    if (editingComponente) {
      editingComponente.dados.texto = texto; // Atualiza diretamente o campo texto
      
      try {
        await handleSaveComponent('CompleteTexto', editingComponente.dados, editingComponente.id);
      } catch (error) {
        console.error('Erro ao salvar o componente:', error);
      }
    }
  };
  

  const handleSaveComponentImageOcclusion = async (data: any) => {
    await handleSaveComponent('ImageOcclusion', data, editingComponente!.id);
  };

  const handleSaveComponentPreencherQuadro = async (data: any) => {
    await handleSaveComponent('PreencherQuadro', data, editingComponente!.id);
  };

  const handleSaveComponentSequencingGame = async (
    title: string,
    phases: Phase[],
    activities: Activity[],
    considerarOrdem: boolean,
    comment?: string,
  ) => {
    if (editingComponente) {
      const dados = {
        title,
        phases,
        activities,
        considerarOrdem,
        comment,
      };
  
      await handleSaveComponent('SequencingGame', dados, editingComponente.id);
    }
  };
  

  const handleSaveComponentPreencherItemCategoria = async (
    title: string,
    categories: { [key: string]: string[] },
    considerarOrdem: boolean,
    comment?: string,
  ) => {
    if (editingComponente) {
      const dados = {
        title,
        categories,
        considerarOrdem,
        comment
      };
  
      await handleSaveComponent('PreencherItemCategoria', dados, editingComponente.id);
    }
  };

  const handleSaveComponentItensSelecao = async (
    question: string,
    correctItems: string[],
    incorrectItems: string[],
    comment?: string,
  ) => {
    if (editingComponente) {
      const dados = {
        question,
        correctItems,
        incorrectItems,
        comment,
      };
  
      await handleSaveComponent('ItensSelecao', dados, editingComponente.id);
    }
  };
  

  const handleSaveComponentArrastarSoltarCategoria = async (
    title: string,
    question: string,
    categories: { [key: string]: string[] },
    uncategorizedItems: string[],
    comment?: string,
  ) => {
    if (editingComponente) {
      const dados = {
        title,
        question,
        categories,
        comment
      };
  
      await handleSaveComponent('ArrastarSoltarCategoria', dados, editingComponente.id);
    }
  };
  
  

  const handleSaveComponentOrdenacao = async (question: string, stages: any[]) => {
    const dados = {
        question,
        stages,
    };
    await handleSaveComponent('Ordenacao', dados, editingComponente!.id);
  };

  const handleSaveComponentPerguntaDigitado = async (
    question: string,
    respostaCorreta: string,
    comment: string
  ) => {
    await handleSaveComponent('PerguntaDigitado', { question, respostaCorreta, comment }, editingComponente!.id);
  };

  const handleSaveComponentPerguntaMultiplaEscolha = async (
    question: string,
    options: { text: string; correct: boolean }[],
    questionType: 'single' | 'multiple',
    comment: string
  ) => {
    await handleSaveComponent(
      'PerguntaMultiplaEscolha',
      { question, options, questionType, comment },
      editingComponente!.id
    );
  };

  const handleSaveComponentCard = async (
    front: string,
    back: string,
    mode: 'flip' | 'reveal',
    comment: string
  ) => {
    await handleSaveComponent('Card', { front, back, mode, comment }, editingComponente!.id);
  };

  const handleSaveComponentTreinoFlashCard = async (treinoFlashCardsData: TreinoFlashCardData[]) => {
    await handleSaveComponent('TreinoFlashCard', { treinoFlashCardsData }, editingComponente!.id);
  };

  const handleSaveComponentTreinoTermoDefinicao = async (data: any) => {
    await handleSaveComponent('TreinoTermoDefinicao', data, editingComponente!.id);
  };

  const handleSaveComponentTimeLine = async (
    title: string,
    phases: { name: string; order: number; items: string[] }[],
    considerarOrdem: boolean
  ) => {
    if (editingComponente) {
      const dados = {
        title,
        phases,
        considerarOrdem,
      };
  
      await handleSaveComponent('TimeLine', dados, editingComponente.id);
    }
  };
  

  const handleSaveComponentTexto = async (html: string) => {
    await handleSaveComponent('Texto', { html }, editingComponente!.id);
  };

  const handleSaveComponentExcalidraw = async (data: { elements: any[]; appState: any }) => {
    if (editingComponente) {
        // Atualiza diretamente os dados do componente em edição
        const updatedDados = {
            elements: data.elements,
            appState: data.appState,
        };

        // Salva o componente com os dados atualizados
        await handleSaveComponent('Excalidraw', updatedDados, editingComponente.id);
    }
};


  const handleSaveComponentGoJS = async (data: { nodes: any[]; links: any[] }) => {
    if (editingComponente) {
      const newComponente = {
        ...editingComponente,
        dados: { nodes: data.nodes, links: data.links },
      };

      await handleSaveComponent('GoJS', newComponente.dados, editingComponente.id);
    }
  };

  const handleSaveComponent = async (type: string, dados: any, componenteId: number) => {
    try {
      await updateComponente(componenteId, { ...dados, type });
      setComponentes((prevState) =>
        prevState.map((comp) => (comp.id === componenteId ? { ...comp, dados, type } : comp))
      );
      setSuccessMessage(`${type} ${componenteId === 0 ? 'adicionado' : 'atualizado'} com sucesso`);

      if (componenteId === 0) {
        setEditingComponente({
          id: 0,
          tipo: type,
          dados: {},
        });
      } else {
        setEditingComponente(null);
        setIsEditorOpen(false);
      }
    } catch (error) {
      console.error('Erro ao salvar o componente:', error);
    } finally {
      setIsEditorOpen(false);
      setEditingComponente(null);
    }
  };

  const handleSaveComponentNew = async (type: string, dados: any, componenteId: number) => {
    try {
      setSuccessMessage(`${type} adicionado com sucesso`);

      setComponentes((prevState) => [
        ...prevState,
        { id: componenteId, tipo: type, dados },
      ]);

      /*setIsAddComponentModalOpen(false);
      setTimeout(() => {
        setIsAddComponentModalOpen(true);
      }, 0);*/
    } catch (error) {
      console.error('Erro ao salvar o componente:', error);
    } finally {
      //setIsEditorOpen(false);
      setEditingComponente(null);
    }
  };

  return (
    <div className="RevisaoEspacadaEdicaoPage-container">
      <div className="RevisaoEspacadaEdicaoPage-baralhos">
        <TreeTable value={baralhos}>
          <Column
            field="nome"
            header="Baralhos"
            expander
            body={(node: TreeNode) => (
              <span
                className={`RevisaoEspacadaEdicaoPage-baralhoNome ${selectedBaralho && selectedBaralho.id === node.data.id ? 'selected-baralho' : ''}`}
                onClick={() => handleBaralhoClick(node)}
                style={{ cursor: 'pointer' }}
              >
                {node.data.nome}
              </span>
            )}
          />
        </TreeTable>
      </div>
      <div className="RevisaoEspacadaEdicaoPage-componentes">
        
        {selectedBaralho ? (
          <>
            <h3>Componentes do Baralho: {selectedBaralho.nome}</h3>
            {renderFiltros()}
            <table>{renderComponentes()}</table>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsAddComponentModalOpen(true)}
            >
              Adicionar Componente
            </Button>
          </>
        ) : (
          <p>Selecione um baralho para ver os componentes.</p>
        )}
      </div>
      {selectedBaralho && (
        <AddComponenteModal
          open={isAddComponentModalOpen}
          onClose={() => setIsAddComponentModalOpen(false)}
          onSave={(type, data) => handleSaveComponentNew(type, data, selectedBaralho.id)}
          baralhoId={selectedBaralho.id}
          usuarioId={usuarioId}
        />
      )}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          Tem certeza de que deseja excluir este componente?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={confirmDelete} color="secondary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      {successMessage && (
        <Toast message={successMessage} onClose={() => setSuccessMessage(null)} />
      )}

      <Dialog
        open={isEditorOpen}
        onClose={() => setIsEditorOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          {editingComponente ? 'Editar Componente' : 'Adicionar Componente'}
        </DialogTitle>
        <DialogContent>{renderEditor()}</DialogContent>
      </Dialog>
      <Dialog
        open={!!viewingComponente}
        onClose={() => setViewingComponente(null)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Visualizar Componente</DialogTitle>
        <DialogContent>{renderViewer()}</DialogContent>
        <DialogActions>
          <Button onClick={() => setViewingComponente(null)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDadosDialogOpen}
        onClose={() => setIsDadosDialogOpen(false)}
        maxWidth="md"
        fullWidth
    >
        <DialogTitle>Dados Completos</DialogTitle>
        <DialogContent>
            <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{dadosCompletos}</pre>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setIsDadosDialogOpen(false)} color="primary">
                Fechar
            </Button>
        </DialogActions>
    </Dialog>

    </div>
  );
};

export default RevisaoEspacadaEdicaoPage;
